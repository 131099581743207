import { NgxLoggerLevel } from "ngx-logger";

export const environment = {
    production: true,
    apiUrl: 'https://azapi-ivrwizard-staging.azurewebsites.net/api/ivr/',
    clientId: '6369070f-ad05-4e8f-b32b-be9dae4e9b99',
    authority: 'https://login.microsoftonline.com/7801d8bd-c3ee-43a8-a040-56f89cb38318',
    redirectUri: 'https://ivrwizardstaging.continentalmessage.com',
    Apikey: 'A4E48E9C-4EA4-4BF3-8BFC-DB5F395BEB90',
    logapiUrl: '', // Replace with remote API
    logLevel: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.DEBUG,
   
    appInsights: {
      instrumentationKey: '4e4ce236-60ef-463a-adc9-37c619d5e6d2'
  }
  
   
  };